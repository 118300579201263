/**
 * Intelligent Ask Amount has changed names to Intelligent Choice. Please note,
 * Intelligent Ask Amount and Intelligent Choice may be used interchangeably in tickets and
 * conversations about this feature.
 *
 * The end point will remain named intelligentAskAmount until it's decided to clean up and rename
 * the end point in APIv2 for consistency.
 */
import { getAbortSignal, handleFetchWithAbortException } from 'utils/abortSignal'

export interface IntelligentAskAmountResponse {
  suggested_donation_amount: string | null
  suggested_recurring_amount: string | null
  grid: {
    suggested_donation_amount: number[] | null
    suggested_recurring_amount: number[] | null
  }
  /**
   * is_default_recurring will be null unless:
   * - If intelligent choice is on for both one-time and recurring and
   * - If the campaign is eligible for the experiment
   */
  is_default_recurring: boolean | null
  /**
   * this will handle whether the CPB block should a nudge in the donation block
   */
  show_recurring_upsell: boolean | null
}

export interface IntelligentAskAmountEventPayload extends IntelligentAskAmountResponse {
  isLoading: boolean
}

const iaaEventPayloadDefault: IntelligentAskAmountEventPayload = {
  isLoading: false,
  suggested_donation_amount: null,
  suggested_recurring_amount: null,
  grid: {
    suggested_donation_amount: null,
    suggested_recurring_amount: null,
  },
  is_default_recurring: null,
  show_recurring_upsell: null,
}

export const getIntelligentAskAmount = async (campaignId: string) => {
  const screenHeight = window?.screen?.height || 0
  const screenWidth = window?.screen?.width || 0

  try {
    const response = await fetch('/api/intelligentAskAmount', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      // Abort the fetch if it takes longer than 1 second
      signal: getAbortSignal(1000),
      body: JSON.stringify({
        campaignId,
        screenHeight,
        screenWidth,
      }),
    })

    const intelligentAskAmountResponse: IntelligentAskAmountResponse = await response.json()
    const intelligentAskAmountEventPayload: IntelligentAskAmountEventPayload = {
      ...intelligentAskAmountResponse,
      isLoading: false,
    }
    return intelligentAskAmountEventPayload
  } catch (e) {
    handleFetchWithAbortException(
      e as Error,
      `Unable to fetch Intelligent Ask Amount for ${campaignId}`,
    )
    return iaaEventPayloadDefault
  }
}
